'use client';

import { isBrowser } from '@next-app/utils/common-utils';

const AlgonomyInitialize = () => {
  if (isBrowser())
    (window as any).algonomyPropsData = (window as any).algonomyPropsData || [];

  return <></>;
};

export default AlgonomyInitialize;
