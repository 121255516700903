'use client';

import React, { useState, useEffect } from 'react';
import classes from './stay-connected.module.scss';
import Link from '@next-app/components/elements/Link/Link';
import Input from '@next-app/components/elements/Input/Input';
import { useSelector } from 'react-redux';
import { stayConnectedNames } from '@next-app/config/elementNameConfig';
import Button from '@next-app/components/elements/Button/Button';
import { setCookie, getCookie } from '../../../utils/cookies';
import constants from '@next-app/constants/constants';
import { stayConnectedUser } from './helper';
import { EmailSignUpDTMEvent } from '@next-app/utils/dtm-event';
import { SeoItem } from '@next-app/interface/Common';
import { getUniqueKey } from '@next-app/utils';
import { dyEvent } from '@next-app/utils/dy-event';
import Conditional from '@next-app/components/Conditional';

const StayConnected = () => {
  const [email, setEmail] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [ishome, setIshome] = useState(false);
  const [isSection, setIsSection] = useState(true);
  const [isEmailLightboxClosed, setIsEmailLightboxClosed] = useState(false);
  const [successToastMessage, isSuccessToastMessage] = useState('');
  const [show, setShow] = useState(false);
  const [error, setErrors] = useState({
    emailError: '',
    postalCodeError: '',
    userShopError: '',
  });

  const { sessionInfo, init, seoData } = useSelector(
    (state: any) => state.init,
  );

  const { lightbox } = constants.stayConnectedProperties;

  useEffect(() => {
    setIsEmailLightboxClosed(getCookie('emailLightboxClosed') === 'true');
    setShow(true);
    if (init?.initResponse) {
      const { initResponse } = init;
      const { emailLightBoxPages } = initResponse;
      const homeUrl = window.location.href;
      const afterSlashValue = homeUrl.split('/')[1];

      if (afterSlashValue === 'home' || afterSlashValue == '') {
        if (emailLightBoxPages.includes('home')) {
          setIshome(true);
        }
      }
    }
  }, [init]);

  useEffect(() => {
    const isEmailLightboxSubmittedOrClosed =
      getCookie('emailLightboxSubmittedOrClosed') === 'true';

    if (show && !isEmailLightboxClosed && !isEmailLightboxSubmittedOrClosed) {
      document.body.classList.add('stay-connected-open');
    } else {
      document.body.classList.remove('stay-connected-open');
    }

    return () => {
      document.body.classList.remove('stay-connected-open');
    };
  }, [show, isEmailLightboxClosed]);

  useEffect(() => {
    if (!isEmailLightboxClosed) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isEmailLightboxClosed]);

  if (!Object.keys(sessionInfo).length) {
    return null;
  }

  const { profile, showEmailLightbox, dynSess } = sessionInfo.sessionInfo;
  const { loginStatus } = profile;
  const { dynSessConfNumber } = dynSess;

  const handleItemClick = (
    text: any,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setButtonText(text);
  };

  const handleValidation = (formExceptions: any) => {
    const isEmailError = formExceptions.find(
      (el: any) => el.propertyPath === 'email',
    );
    const isPostalCodeError = formExceptions.find(
      (el: any) => el.propertyPath === 'postalCode',
    );

    const isUserShopError = formExceptions.find(
      (el: any) => el.propertyPath === 'userShopFor',
    );

    if (isEmailError) {
      setErrors((prev) => ({
        ...prev,
        emailError: isEmailError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        emailError: '',
      }));
    }

    if (isPostalCodeError) {
      setErrors((prev) => ({
        ...prev,
        postalCodeError: 'Please enter Zip Code.',
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        postalCodeError: '',
      }));
    }

    if (isUserShopError) {
      setErrors((prev) => ({
        ...prev,
        userShopError: isUserShopError.localizedMessage,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        userShopError: '',
      }));
    }
  };

  const closeFlyout = () => {
    setCookie('emailLightboxClosed', 'true', 30);
    setShow(false);
  };

  const handleSubmit = async () => {
    const stayConnectedUserResponse: any = await stayConnectedUser({
      email,
      postalCode,
      userShopFor: buttonText,
      source: lightbox,
      sessionConfirmationNumber: dynSessConfNumber,
    });

    if (stayConnectedUserResponse.formError) {
      const { formExceptions } = stayConnectedUserResponse;
      await handleValidation(formExceptions);
    } else {
      setCookie('emailLightboxSubmittedOrClosed', 'true', 30);
      setIsActive(!isActive);
      setIsSection(false);
      const { responseDetails } = stayConnectedUserResponse;
      const { successMessage } = responseDetails;
      isSuccessToastMessage(successMessage);

      await setErrors({
        emailError: '',
        postalCodeError: '',
        userShopError: '',
      });
      setTimeout(() => {
        setShow(false);
      }, 2000);

      const allSeoData: SeoItem[] = seoData;
      const pageData = allSeoData.find((item) => item.url === '/');

      const dyData = {
        eventName: 'Newsletter Subscription',
        type: 'newsletter-subscription-v1',
        email: email,
      };
      dyEvent(dyData);

      EmailSignUpDTMEvent({
        userShopFor: buttonText,
        lowerEnv: init?.initResponse?.lowerEnv,
        email,
        encryptedOrderId: sessionInfo?.cartInfo?.encryptedOrderId,
        orderId: sessionInfo?.cartInfo?.cartId,
        pageTitle: pageData?.title,
      });
    }
  };

  const isEmailLightboxCookiePresent =
    getCookie('emailLightboxSubmittedOrClosed') !== undefined &&
    getCookie('emailLightboxSubmittedOrClosed') !== null;

  if (loginStatus !== 'guest') {
    return null;
  }

  const userShopErrorBottom = error.userShopError
    ? classes.userShopBottomerror
    : '';

  return (
    <>
      <Conditional
        if={
          showEmailLightbox &&
          !isEmailLightboxCookiePresent &&
          !isEmailLightboxClosed
        }
      >
        <Conditional if={ishome}>
          <div
            className={`${classes['stay-connected-wrap']} ${show ? classes['show'] : ''}`}
          >
            {isSection && (
              <div className={`${classes['stay-connected']}`}>
                <div className={`${classes['stay-left-section']}`}>
                  <strong> {stayConnectedNames.stayConnected}</strong>
                  <p>
                    {stayConnectedNames.signUpSpecialOffer}{' '}
                    <Link href="/about/privacy-policy">
                      {' '}
                      {stayConnectedNames.seePrivacyPolicy}
                    </Link>
                  </p>
                </div>
                <form>
                  <div
                    className={`${classes['stay-connected-form-fields']} ${classes['email']}`}
                  >
                    <div className={`${classes['form-field-error']}`}>
                      {error?.emailError && (
                        <>
                          <span className={`${classes['icon']}`}></span>
                          <span className={`${classes['error-msg']}`}>
                            {error?.emailError}
                          </span>
                        </>
                      )}
                    </div>
                    <Input
                      type="email"
                      placeholder="Email"
                      customClass={error.emailError ? classes.Inputerror : ''}
                      datatestid="input-email"
                      id={`${getUniqueKey(1, 'input-email')}`}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className={`${classes['stay-connected-form-fields']} ${classes['zipcode']}`}
                  >
                    <div className={`${classes['form-field-error']}`}>
                      {error?.postalCodeError && (
                        <>
                          <span className={`${classes['icon']}`}></span>
                          <span className={`${classes['error-msg']}`}>
                            {error?.postalCodeError}
                          </span>
                        </>
                      )}
                    </div>
                    <Input
                      type="text"
                      customClass={
                        error.postalCodeError ? classes.Inputerror : ''
                      }
                      placeholder="Zip code"
                      datatestid="input-postal-code"
                      name="postal code"
                      id={`${getUniqueKey(1, 'input-postal-code')}`}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </div>
                  <div
                    className={`${classes['stay-connected-form-fields']} ${classes['select-user-shop']}`}
                  >
                    <div className={`${classes['form-field-error']}`}>
                      {error?.userShopError && (
                        <>
                          <span className={`${classes['icon']}`}></span>
                          <span className={`${classes['error-msg']}`}>
                            {error?.userShopError}
                          </span>
                        </>
                      )}
                    </div>
                    <input
                      type="text"
                      className={`btn btn-primary dropdown-toggle ${classes['buttonList']} ${userShopErrorBottom}`}
                      data-bs-toggle="dropdown"
                      placeholder="I typically shop for"
                      name="typically-shop-for"
                      defaultValue={
                        stayConnectedNames[buttonText] || buttonText
                      }
                    />
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          data-testid="dropdown-list"
                          className="dropdown-item"
                          href=""
                          onClick={(e) => handleItemClick('classroom', e)}
                        >
                          {stayConnectedNames.classroom}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href=""
                          onClick={(e) => handleItemClick('home', e)}
                        >
                          {stayConnectedNames.home}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href=""
                          onClick={(e) => handleItemClick('all', e)}
                        >
                          {stayConnectedNames.all}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item">I typically shop for</a>
                      </li>
                    </ul>
                  </div>
                  <Button
                    data-testid="submit-stay-connected"
                    customClass={`${classes['stay-connected-button']}`}
                    onClick={() => handleSubmit()}
                  >
                    {stayConnectedNames.signUp}
                  </Button>
                </form>
              </div>
            )}
            <span
              data-testid="close-button"
              onClick={closeFlyout}
              className={`${classes['close-icon']}`}
            ></span>
          </div>
        </Conditional>
      </Conditional>
      {isActive && (
        <div
          className={`${classes['success-message']} ${show ? classes['show'] : ''}`}
        >
          <div>{successToastMessage}</div>
          <span
            data-testid="close-button"
            onClick={closeFlyout}
            className={`${classes['close-icon']}`}
          ></span>
        </div>
      )}
    </>
  );
};

export default StayConnected;
