import { makeFetchCall } from '../../../../app/utils/fetch-data';
import urlMapping from '@next-app/config/urlMapping';

export const stayConnectedUser = async ({
  email,
  postalCode,
  userShopFor,
  source,
  sessionConfirmationNumber,
}: {
  email: string;
  postalCode: any;
  userShopFor: string;
  source: string;
  sessionConfirmationNumber: string;
}) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.stayConnectedUrl}`,
      options: {
        method: 'POST',
      },

      body: {
        email: email,
        postalCode: postalCode,
        userShopFor: userShopFor,
        source: source,
        _dynSessConf: sessionConfirmationNumber,
      },
    });
    return response;
  } catch (error) {
    console.error('Error Stay connected in:', error);
  }
};
