'use client';

import { useDispatch, useSelector } from 'react-redux';
import { setSeo } from '@next-app/lib/store';
import { useEffect } from 'react';

const SeoSetup = ({ seoItem }: any) => {
  const initial = useSelector((state: any) => state.init);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      initial &&
      initial.sessionInfo &&
      Object.keys(initial.sessionInfo).length
    ) {
      const { seoData: prevSeo = [] } = initial;
      const newData =
        (prevSeo &&
          Array.isArray(prevSeo) &&
          prevSeo.length &&
          prevSeo?.find((el) => el.url === seoItem[0].url)) ||
        {};
      const newSeo =
        newData && Object.keys(newData).length
          ? prevSeo
          : [...prevSeo, ...seoItem];

      dispatch(
        setSeo({
          seoData: newSeo,
        }),
      );
    }
  });

  return null;
};

export default SeoSetup;
