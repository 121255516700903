'use client';

import configuration from '@next-app/config/configurations';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import urlMapping from '@next-app/config/urlMapping';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import { isPagePDP } from '@next-app/utils/isPagePDP';
import { setData } from '@next-app/lib/store';
import { isBrowser } from '@next-app/utils/common-utils';

interface IAlgonomyData {
  strategy_message: string;
  strategy_name: string;
  placement_name: string;
  page_area_name: string;
  items: [{ id: string }];
}

const GetAlgonomyScript = () => {
  const { baseUrl } = configuration;
  const [algonomyData, setAlgonomyData] = useState<
    IAlgonomyData[] | undefined
  >();
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState('');
  const stateData = useSelector((state: any) => state.init);
  const { sessionInfo, init } = stateData;

  let propsData;
  if (isBrowser()) {
    propsData = (window as any).algonomyPropsData;
  }
  const algonomyPropsData = propsData || [];
  let MAX_RETRIES = 1;

  useEffect(() => {
    if (
      Object.keys(init).length &&
      Object.keys(sessionInfo).length &&
      isBrowser()
    ) {
      const {
        dynSess: { dynSessConfNumber },
      } = sessionInfo.sessionInfo;
      const { algonomyAPIKey, algonomyBaseURL } = init.initResponse.recsInfo;
      setSessionId(dynSessConfNumber);
      const script = document.createElement('script');
      script.src = '/react/scripts/algonomy/p13n.js';
      script.async = true;
      script.onload = () => {
        (window as any).RR.jsonCallback = function () {
          setAlgonomyData((window as any).RR.data.JSON.placements);
        };
        if (algonomyPropsData.length > 0) {
          let r3Common = new (window as any).r3_common();
          r3Common.setApiKey(algonomyAPIKey);
          r3Common.setBaseUrl(algonomyBaseURL);
          r3Common.setSessionId(dynSessConfNumber);
          r3Common.addPlacementType('pid');
          r3Common.setClickthruServer(baseUrl);

          try {
            algonomyPropsData.map((propsData: any) => {
              r3Common.addPlacementType(propsData?.placementId);
            });
          } catch (error) {
            console.log('🚀 ~ useEffect ~ error:', error);
          }
          (window as any).r3();
        }
      };
      document.body.appendChild(script);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, algonomyPropsData]);

  const productDataCarouselData: any = [];
  useEffect(() => {
    if (algonomyData) {
      algonomyData.map((algonomy: any) => {
        algonomyPropsData.map((propsData: any) => {
          if (algonomy.placement_name === propsData.placementId) {
            const titleVal = isPagePDP(propsData.page)
              ? propsData.recommendationTitle
              : !propsData.showTitle
                ? algonomy.strategy_message !== ''
                  ? algonomy.strategy_message
                  : propsData.recommendationTitle
                : propsData.recommendationTitle;
            const itemsId: any = [];
            const algonomyItems = algonomy.items;
            algonomyItems.map((data: { id: string }) => {
              itemsId.push(data.id);
            });

            const recommendedProductsId = itemsId.length
              ? itemsId.join()
              : propsData.cartRecommProducts;

            fetchProductRecsData(
              recommendedProductsId,
              propsData.placementId,
              titleVal,
            );
          }
        });
      });
    }

    async function fetchProductRecsData(
      recommendedProductsId: string,
      placementId: string,
      title: string,
      sessionConf?: string,
    ) {
      if (sessionId) {
        if (!sessionConf) {
          sessionConf = sessionId;
        }
        const body: any = {
          recommendedProducts: recommendedProductsId,
          maxProdsPerPage: '25',
          isDisplaySpecialPrice: true,
          _dynSessConf: sessionConf,
        };
        let fallbackProductRecsData: any =
          MAX_RETRIES > 0 &&
          (await makeFetchCall({
            endpoint: urlMapping.fetchRecsProductInfo,
            options: {
              method: 'POST',
            },
            body,
          }));

        if (fallbackProductRecsData?.stringSessionConfirmationNumber) {
          MAX_RETRIES--;

          const updatedSessionInfo = {
            ...sessionInfo,
            sessionInfo: {
              ...sessionInfo.sessionInfo,
              dynSess: {
                dynSessConfNumber:
                  fallbackProductRecsData.stringSessionConfirmationNumber,
              },
            },
          };
          dispatch(
            setData({
              ...stateData,
              sessionInfo: updatedSessionInfo,
            }),
          );
          setSessionId(
            fallbackProductRecsData?.stringSessionConfirmationNumber,
          );
          fetchProductRecsData(
            recommendedProductsId,
            placementId,
            title,
            fallbackProductRecsData?.stringSessionConfirmationNumber,
          );
        } else {
          productDataCarouselData.push({
            title,
            recsProductInfo: fallbackProductRecsData.recsProductInfo,
            placementId: placementId,
          });
          if (productDataCarouselData.length === algonomyPropsData.length) {
            dispatch(
              setData({
                ...stateData,
                productRecsData: productDataCarouselData,
              }),
            );
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algonomyData]);

  return <></>;
};

export default GetAlgonomyScript;
